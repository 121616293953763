export default [
    {
        title: "Главная",
        icon: "mdi-home",
        to: "/"
    },
        {
        title: "Битва умов",
        icon: "mdi-head-snowflake-outline",
        to: "/battle"
    }
]