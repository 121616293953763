<template>

    <v-container class="down-top-padding pa-7" fluid id="refCont">

        <v-row><v-col cols="3" style="background-color: #e0e0e0;  min-height: 100vh;">

                <v-list nav
                        dense

                        >
                    <div v-for="group in groups" :key="group.id">
                        <v-list-item v-if="group.sub==0" color='blue'
                                     @click="getArticle(group.id)"
                                     v-bind:class="article.id && group.id==article.id ? 'v-list-item--active':''"
                                     link
                                     >

                            <v-list-item-content>
                                {{ group.name }}
                            </v-list-item-content>

                        </v-list-item>
                        <v-list-group   v-if="group.sub==1">
                            <template v-slot:activator>

                                <v-list-item-content>
                                    {{ group.name }}
                                </v-list-item-content>
                            </template>
                            <div v-for="sub in group.subgroups"
                                 :key="sub.id">
                                <v-list-item v-if="sub.sub==0"    
                                             color='blue'
                                             v-bind:class="article.id && sub.id==article.id ? 'v-list-item--active':''"
                                             @click="getArticle(sub.id)"
                                             style="padding-left: 23px;"
                                             link
                                             >
                                    <v-list-item-title v-text="sub.name"></v-list-item-title>
                                </v-list-item>
                                <v-list-group  v-if="sub.sub==1" no-action 
                                               sub-group >
                                    <template v-slot:activator>

                                        <v-list-item-title>
                                            {{ sub.name }}
                                        </v-list-item-title>
                                    </template>

                                    <v-list-item  color='blue' style="padding-left: 35px;"
                                                  v-bind:class="article.id && subsub.id==article.id ? 'v-list-item--active':''"
                                                  @click="getArticle(subsub.id)"
                                                  v-for="subsub in sub.subgroups"
                                                  :key="subsub.id"
                                                  link

                                                  >
                                        <v-list-item-title v-text="subsub.name"></v-list-item-title>
                                    </v-list-item>
                                </v-list-group>
                            </div>
                        </v-list-group>
                    </div>
                </v-list>


            </v-col>
            <v-col cols="9" class="pt-0">
                <v-text-field
                    v-model="searchString"
                    prepend-inner-icon="mdi-magnify"
                    label="Поиск по справочнику..."
                    filled
                    background-color="transparent"
                    hide-details
                    ></v-text-field>
                <div v-if='overlay' class='ma-7'>
                    <center><v-progress-circular

                            indeterminate
                            size="64"
                            ></v-progress-circular></center>
                </div>
                <div v-else>
                    <div v-if="searchString">
                        <div v-if='results.length>0'>
                        <v-card v-for="res in results" :key="res.id" class="pt-3 px-3 mt-3" @click="getArticle(res.id)" style="cursor:pointer;">
                            <v-card-subtitle>
                                <span v-html="$options.filters.highlight(res.name, searchString)"></span>
                            </v-card-subtitle>
                            <v-card-text>
                                <span v-html="hightlight(res.text)"></span>
                            </v-card-text>
                        </v-card>
                        </div>
                        <div v-else class='ma-7'>
                            <center><h3>Ничего не найдено</h3></center>
                        </div>
                    </div>
                    <div class="pa-3" v-if="article && !searchString" v-html="article.text"></div>
                </div>
            </v-col>
        </v-row>

    </v-container>

</template>

<style>
    #refCont  .v-list-group--sub-group .v-list-item__icon:first-child {
        margin-right: 0px;
    }
    #refCont  .v-list--dense .v-list-group--sub-group .v-list-group__header {
        padding-left: 0px;
    }
    #refCont .highlight {
        font-weight: bold;
    }

    #refCont table{
        margin:16px auto 10px auto;
        table-layout: auto;
    }

    #refCont table, #refCont  td,#refCont th {
        padding:5px 10px 5px 10px;
        border: 1px solid #C0C0C0;
    }

    #refCont th {
        font-size: 14px;
        border: 1px solid #adadad;
        background: #dbedf7;
    }

    #refCont th.turn {
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-1);
    }
    #refCont td img {
        margin: 0;
    }
    #refCont   tr:nth-child(2n) {
        background: #e9e9e9; 
    }


    #refCont details {
        width: 96%;
        min-height: 5px;
        padding: 20px 30px 20px 20px;
        margin: 0 auto;
        position: relative;
        font-size: 18px;
        border: 1px solid rgba(0,0,0,.1);
        box-sizing: border-box;
        transition: all .3s;
    }

    #refCont details + details {margin-top: 20px;}

    #refCont details[open] {
        min-height: 50px;
        background-color: #f6f7f8;
        padding-top:20px; 
    }

    #refCont details[open] h3 {padding-bottom:20px;}

    #refCont details.rf { padding: 15px 30px 15px 15px;}
    #refCont details.rf h3{ 
        font-size: 14px;
        text-align: left;	
    }
    #refCont details.rf p { 
        margin: 0 0 15px 0;
    }
    #refCont details.rf + details.rf {margin-top: 5px;}

    #refCont summary {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
    }
    #refCont summary h3 {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    #refCont summary:focus {
        outline: none;
    }

    #refCont summary:focus::after {
        content: "";
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    #refCont summary::-webkit-details-marker {
        display: none
    }
    #refCont .control-icon {
        fill: #002e7b;
        transition: .3s ease;
        pointer-events: none;
    }
    #refCont .control-icon-close {display: none;}

    #refCont details[open] .control-icon-close {
        display: initial;
        transition: .3s ease;
    }

    #refCont details[open] .control-icon-expand {
        display: none;
    }
    /* Буква в круге */
    #refCont .round{
        color: #000;
        border: 2px solid #004E9B;
        text-align: center;
        font-size: 20px;
        line-height: 34px;
        font-weight: 600;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        float:left;
        margin: 4px 10px 20px 0px;
        clear: both; 
    }
    #refCont span.round ~ p{
        padding-top: 13px;
    }

    /* Вкладки */
    #refCont ul.i-tab {overflow:hidden; padding: 0; text-align: center;}
    #refCont ul.i-tab li {float:left; list-style:none;}

    #refCont ul.i-tab li .round {
        margin: 10px 15px 0px 0px;
    }
    #refCont ul.i-tab li.active .round {
        border: 2px solid #f0bf00;   
        width: 38px; height: 38px;
        line-height: 38px;
        margin: 10px 15px 0px 0px;
    }

    #refCont ul.tab-content {list-style:none; padding: 0; margin: 0;}
    #refCont ul.tab-content li {display:none;}
    #refCont .entry-content{
        padding-top:10px; 
    }
    /* Расчет сил и средств */
    #refCont .formmm {text-align: center; margin-top: 20px;}
    #refCont .formula {
        background-color: #004E9B;
        border: #002e7b 1px solid;
        color: #fff;
        padding: 10px 20px 10px 20px;
        font-weight: bold;
    }
</style>
<script>
    const debounce = function (f, ms) {
        let timer = null;
        return function (...args) {
            const onComplete = () => {
                f.apply(this, args);
                timer = null;
            }
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(onComplete, ms);
        };
    }
    export default {
        name: "index",
        data: () => ({
                overlay: false,
                searchString: '',
                article: {},
                groups: [],
                results: []

            }),
        methods: {
            hightlight(text) {

                if (typeof text === 'undefined') {
                    return true
                }

                text = text.replace(/<\/?[^>]+>/g, '')
                if (typeof text === 'undefined') {
                    return true
                }
                var check = new RegExp(this.searchString, "ig");
                if( text.search(check) == -1){
                    return
                }
                text = text.replace(check, function (matchedText) {
                    return ('<strong>' + matchedText + '</strong>');
                });
                let splited = text.split('strong>')
                var lastPart = splited[2].replace('<', '')
                if (lastPart.length > 300) {
                    lastPart = lastPart.substring(0, 300) + "...";     // итоговая длина равна maxlength
                }
                lastPart = lastPart + "..."
                var firstPart = splited[0].replace('<', '')
                if (firstPart.length > 300) {
                    firstPart = firstPart.substring(-300, 300);     // итоговая длина равна maxlength
                }
                firstPart = "..." + firstPart
                var string = firstPart + '<strong>' + splited[1].replace('</', '') + '</strong>' + lastPart
                return string

            },
            fetchGroups() {
                this.$http.get('reference/get-list').then((response) => {
                    this.groups = response.data
                })
            },
            getArticle(id) {
                this.overlay = true
                this.$http.get('reference/get-article?id=' + id).then((response) => {
                    this.searchString = ''
                    this.overlay = false
                    this.article = response.data

                })
            },
            searchArticles() {
                this.overlay = true
                this.$http.get('reference/search?string=' + this.searchString).then((response) => {
                    this.overlay = false
                    this.results = response.data

                })

            },
            search: debounce(function () {
                if (this.searchString.length > 0)
                {
                    this.searchArticles()
                }
            }, 500),
        },
        watch: {
            searchString() {
                this.search()
            },
        },
        mounted() {
            this.fetchGroups()
        }
    };
</script>