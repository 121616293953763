<template>
    <v-app id="inspire">
        <Header v-model="expandOnHover"></Header>

        <v-content>
            <v-container fluid class="page-wrapper">
                <router-view :escButton="escButton"/>

            </v-container>
        </v-content>
        <Sidebar :expand-on-hover.sync="expandOnHover"></Sidebar>



    </v-app>
</template>

<script>
    import Header from "./header/Header";
    import Sidebar from "./sidebar/Sidebar";


    import { mapState, mapMutations } from "vuex";
    export default {
        name: "Layout",

        components: {
            Header,
            Sidebar
        },

        props: {
            source: String,
            escButton: Object
        },
        data: () => ({
                expandOnHover: false
            }),
        computed: {
            ...mapState(["Customizer_drawer"])
        },
        created(){
        },
        methods: {
            ...mapMutations({
                    setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER"
            })
        }
    };
</script>

<style>
</style>