<template>
    <div>
              <center>
            <h4 class="ma-3">Если у Вас возникли вопросы по работе с сервисом вы можете обратиться за помощью к ответственному сотруднику образовательной организации, закрепленной за вашим Главным управлением</h4>

            <!--<h2 class="ma-3">8 (495) 617-29-78</h2> или отправить соообщение на e-mail: <h2 class="ma-3">support@eios-mchs.ru</h2>-->
            </center>
        <div v-if="!cur_ticket" style="display: none;">
            <v-button v-b-toggle.new variant="primary" ref="new" class="m-3 ">Создать новое обращение</v-button>
            <b-collapse id="new"  class="mt-2">
                <b-card>
                    <b-form >
                        <b-form-group id="input-group-2" label="Тема обращения:" label-for="input-2">
                            <b-form-input
                                id="input-2"
                                v-model="form.title"
                                required
                                placeholder="Введите тему сообщения"
                                ></b-form-input>
                        </b-form-group>
                        <b-form-group id="input-group-2" label="Категория:" label-for="input-2">
                            <b-form-select v-model="form.cat" :options="options"></b-form-select>
                        </b-form-group>
                        <b-form-group id="input-group-3" label="Сообщение:" label-for="input-3">
                            <b-form-textarea
                                id="input-3"
                                v-model="form.text"
                                placeholder="Опишите возникшую проблему"
                                rows="6"
                                max-rows="10"
                                ></b-form-textarea>
                        </b-form-group>

                        <b-button @click="onSubmit" v-if="buzy==false" variant="primary">Отправить</b-button>
                        <b-btn v-else disabled><i class="fa fa-spinner fa-spin fa-fw"></i> Сообщение отправляется</b-btn>
                    </b-form>
                </b-card>
            </b-collapse>
            <b-card class="p-2">
                <h4>Текущие тикеты</h4>
                <b-table v-if="tickets" striped hover :fields="fields" primary-key="id" :items="tickets">
                         <template v-slot:cell(created_at)="data">
                        {{ parseInt(data.item.created_at) | moment('timezone', $moment.tz.guess(), 'lll') }}
                    </template>
                         <template v-slot:cell(status)="data">
                        <h6 v-if="data.item.status==0" class="text-primary">Создан</h6>
                        <h6 v-if="data.item.status==1" class="text-success">В работе</h6>
                        <h6 v-if="data.item.status==2">Закрыт</h6>
                    </template>
                         <template v-slot:cell(actions)="data">
                        <b-btn size="sm" class="m-1" @click="goTo(data.item.ticket_id)">Перейти к собщениям <b-badge variant="success" v-if="data.item.unread">{{data.item.unread.length}}</b-badge></b-btn><b-btn v-if="data.item.status!=2"  @click="close(data.item.ticket_id,data.index)" size="sm" variant="danger" class="m-1">Закрыть</b-btn>
                    </template>
                </b-table>
                <div v-if="!tickets">Отсутствуют</div>
            </b-card>
        </div>
        <div v-else>
            <b-btn @click="cur_ticket=null"> Назад</b-btn>
            <center><h6 class="p-3">{{cur_ticket[0].title}} </h6></center>
            <b-card v-for="message in cur_ticket" :key="message.id">
                <b v-if="message.user_id==$auth.user().id">{{$auth.user().profile.last_name}} {{$auth.user().profile.name}} ({{ parseInt(message.created_at) | moment('timezone', $moment.tz.guess(), 'lll') }})</b>
                <b v-else>Техническая поддержка ({{ parseInt(message.created_at) | moment('timezone', $moment.tz.guess(), 'lll') }})</b> написал<br><br>
                {{message.text}}
            </b-card>
            <div v-if="cur_ticket[0].status!=2">
                        <b-form-group id="input-group-3" label="Сообщение:" label-for="input-3">
                            <b-form-textarea
                                id="input-3"
                                v-model="answer"
                                placeholder="Сообщение"
                                rows="6"
                                max-rows="10"
                                ></b-form-textarea>
                        </b-form-group>
            <b-btn @click="sendAnswer(cur_ticket[0].ticket_id)" variant="primary" v-if="buzy==false"> Отправить</b-btn>
            <b-btn v-else disabled><i class="fa fa-spinner fa-spin fa-fw"></i> Сообщение отправляется</b-btn>
            </div>
            <div v-else>
                <center><h5 class="p-3">Обращение закрыто</h5></center>
            </div>
        </div>
    </div>

</template>
<script>

    export default {
        name: 'keys',
        components: {

        },
        data() {
            return {
                tickets: [],
                cur_ticket: null,
                answer:null,
                error: false,
                options: [
                    {value: null, text: 'Выберите категорию'},
                    {value: 'other', text: 'Другое'},
                ],
                fields: [
                    // A column that needs custom formatting
                    {key: 'id', label: 'ИД'},
                    {key: 'created_at', label: 'Создан'},
                    {key: 'title', label: 'Тема обращения'},
                    {key: 'status', label: 'Статус'},
                    {key: 'actions', label: 'Действия'},
                ],
                buzy: false,
                form: {
                    title: '',
                    text: '',
                    cat: null
                }
            }
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            onSubmit() {
                this.buzy = true
                this.$http.post('tickets/send',
                        this.form
                        ).then(response => {
                    alert('Ваше обращение отправлено');
                    this.tickets.unshift(response.data)
                    this.buzy = false
                    this.$refs.new.click()
                }).catch(() => {
                    alert('Возникла ошибка при отправке сообщения');
                    this.buzy = false
                })
            },
            sendAnswer(ticket_id) {
                this.buzy = true
                this.$http.post('tickets/answer?ticket_id='+ticket_id,
                {
                    text:this.answer
                }
                        ).then(response => {
                    alert('Ваше сообщение отправлено');
                    this.cur_ticket.push(response.data)
                    this.answer=null
                    this.buzy = false
                }).catch(() => {
                    alert('Возникла ошибка при отправке сообщения');
                    this.buzy = false
                })
            },
            goTo(ticketId)
            {
                this.$http.get('tickets/get?ticket_id=' + ticketId)
                        .then(response => {
                            this.cur_ticket = response.data
                        })
                        .catch(e => {
                            this.error = e.response.data
                        })
            },
            close(ticketId,index) {
                this.$http.get('tickets/close?ticket_id=' + ticketId)
                        .then(response => {
                            this.tickets[index].status = response.data
                        })
                        .catch(e => {
                            this.error = e.response.data
                        })
            },
            fetchData() {

                this.$http.get('tickets/list')
                        .then(response => {
                            this.tickets = response.data.tickets
                        })
                        .catch(e => {
                            this.error = e.response.data
                        })

            }
        }
    }
</script>