<template>
    <v-app-bar app clipped-left clipped-right color="primary" dark>
        <!---Logo part -->
        <v-toolbar-title class="align-center d-flex">
            <span class="logo-icon">
                <img src="/gerb.png" id="logo" style="height: 62px; margin-top:10px;"/>
                
            </span>
            <h1 class="font-weight-thin grey--text text--lighten-4" style="font-weight: 900 !important;font-size: 36px; display: inline; margin-top: 5px;">ЭИОС</h1>
        </v-toolbar-title>
        <!---Logo part -->
        <!---/Toggle sidebar part -->
        <div @click="showhideLogo">
            <v-app-bar-nav-icon
                @click="$vuetify.breakpoint.smAndDown ? setSidebarDrawer(!Sidebar_drawer) : $emit('input', !value);"
                />
        </div>
        <!---/Toggle sidebar part -->
        <!---Search part -->

        <!---/Search part -->
        <v-spacer />
        <!---right part -->
        <!---Notification -->
                <!---<v-btn  text class="mr-1">
        <a style="color:white"  target="_blank" href='https://api.eios-mchs.ru/uploads/contest/promo.mp4'>Видеоролик</a>
        </v-btn>
        <v-btn  text class="mr-1" id='manual'>
        <a style="color:white"  target="_blank" href='https://api.eios-mchs.ru/uploads/contest/manual.pdf'>Руководство пользователя</a>
        </v-btn>-->
 
        <v-menu bottom left offset-y origin="top right" transition="scale-transition">
            <template v-slot:activator="{ on }">
                <v-btn dark icon v-on="on" class="mr-1" @click="supportDialog=!supportDialog">
                        <v-icon>mdi-message</v-icon>
 
                </v-btn>
            </template>
        </v-menu>
        <!---Notification -->

        <!---User -->
        <v-menu bottom left offset-y origin="top right" transition="scale-transition">
            <template v-slot:activator="{ on }">
                <v-btn dark icon v-on="on" class="mr-1">
                    <v-avatar size="40">
                        <i class=" fa fa-3x fa-user-circle"></i>
                    </v-avatar>
                </v-btn>
            </template>

            <v-list>
                <v-list-item to="/user/settings">
                    <v-list-item-title>Профиль</v-list-item-title>
                </v-list-item>
                <v-list-item to="/user/security">
                    <v-list-item-title>Настройки доступа</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout()">
                    <v-list-item-title>Выход</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <!---User -->
                <v-dialog
            v-model="supportDialog"
            width="900"
            >
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Техническая поддержка
                </v-card-title>
                <v-card-text>
                    <tickets/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app-bar>
</template>
<script>
// Utilities
    import { mapState, mapMutations } from "vuex";
    import tickets from '../../../views/support/tickets'
    export default {
        name: "Header",

        components: {
            tickets
        },

        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            supportDialog:false,
                showLogo: true,
                showSearch: false,
                notifications: [
                    {
                        title: "Launch Admin",
                        iconbg: "error",
                        icon: "mdi-link-variant",
                        desc: "Just see the my new admin!",
                        time: "9:30AM"
                    },
                    {
                        title: "Event today",
                        iconbg: "success",
                        icon: "mdi-calendar-check",
                        desc: "Just a reminder that you have event",
                        time: "10:30AM"
                    },
                    {
                        title: "Settings",
                        iconbg: "info",
                        icon: "mdi-cog",
                        desc: "You can customize this template as you want",
                        time: "11:30AM"
                    },
                    {
                        title: "Pavan Kumar",
                        iconbg: "indigo",
                        icon: "mdi-account",
                        desc: "Sent you an notification",
                        time: "12:30AM"
                    }
                ],
                href() {
                    return undefined;
                }
            }),

        computed: {
            ...mapState(["Sidebar_drawer"])
        },

        methods: {
            logout() {
                this.$auth.logout({
                    makeRequest: true,
                    redirect: '/authentication/login',
                    success() {
                        console.log('success ' + this.context)
                    },
                    error() {
                        console.log('error ' + this.context)
                    }
                })
            },
            ...mapMutations({
                    setSidebarDrawer: "SET_SIDEBAR_DRAWER"
            }),
            showhideLogo: function () {
                this.showLogo = !this.showLogo;
            },
            searchbox: function () {
                this.showSearch = !this.showSearch;
            }
        }
    };
</script>

<style lang="scss">
    .v-application #main-sidebar.theme--dark.white{
        background:#363636!important;
    }
    .hidelogo{
        display: none;
    }
    .searchinput{
        position: absolute;
        width: 100%;
        margin: 0;
        left: 0;
        z-index: 10;
        padding: 0 15px;
    }
    .descpart{
        max-width:220px;
    }
</style>