<template>
    <v-navigation-drawer
        v-model="Sidebar_drawer"
        :dark="SidebarColor !== 'white'"
        :color="SidebarColor"
        mobile-breakpoint="960"
        clipped

        mini-variant-width="70"
        :expand-on-hover="expandOnHover"
        app
        id="main-sidebar"
        v-bar
        >
        <!---USer Area -->
        <v-list-item two-line class="profile-bg">

            <v-list-item-avatar :size="56">
                <!-- <img v-if="$auth.user().profile.avatar"
                     :src="$auth.user().profile.avatar"
                     >
                <i v-else class=" fa fa-3x fa-user-circle"></i> -->

                <img src="/log.png" alt="">

            </v-list-item-avatar>

            <v-list-item-content class="white--text">
                <v-list-item-title>{{$auth.user().profile.name}} {{$auth.user().profile.last_name}}</v-list-item-title>
                <v-list-item-subtitle class="caption white--text">{{$auth.user().profile.position}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <!---USer Area -->

        <v-list expand nav class="mt-1">
            <template v-for="(item, i) in items">
                <!---If Sidebar Caption -->
                <v-row v-if="item.header" :key="item.header" align="center">
                    <v-col cols="12">
                        <v-subheader v-if="item.header" class="d-block text-truncate">{{ item.header }}</v-subheader>
                    </v-col>
                </v-row>
                <!---If Sidebar Caption -->

                <BaseItemGroup v-else-if="item.children" :key="`group-${i}`" :item="item"></BaseItemGroup>

                <BaseItem v-else :key="`item-${i}`" :item="item" />
            </template>
            <v-list-item v-if="groupLink()"
                to="/groups"
                :active-class="``"
                >
                <v-list-item-icon >
                    <v-icon v-text="'mdi-account-group'" />
                </v-list-item-icon>
                <v-list-item-content >
                    <v-list-item-title v-text="'Группы'" />
                </v-list-item-content>
            </v-list-item>
            
        </v-list>
        <v-list expand nav class="mt-0 pt-0" v-if="$auth.user().userGroups.find(val => val.groupId==19)">
            <v-list-item 
                to="/editor"
                :active-class="``"
                >
                <v-list-item-icon >
                    <v-icon v-text="'mdi-notebook-edit'" />
                </v-list-item-icon>
                <v-list-item-content >
                    <v-list-item-title v-text="'Редактор курсов'" />
                </v-list-item-content>
            </v-list-item>
            
            </v-list>
        <v-list expand nav class="mt-0 pt-0" >
                    <v-list-item
                to="/video"
                :active-class="``"
                >
                <v-list-item-icon >
                    <v-icon v-text="'mdi-video'" />
                </v-list-item-icon>
                <v-list-item-content >
                    <v-list-item-title v-text="'Видеоматериалы'" />
                </v-list-item-content>
            </v-list-item>
                     <v-list-item
                to="/biblio"
                :active-class="``"
                >
                <v-list-item-icon >
                    <v-icon v-text="'mdi-book-open-outline'" />
                </v-list-item-icon>
                <v-list-item-content >
                    <v-list-item-title v-text="'Библиотека'" />
                </v-list-item-content>
            </v-list-item>
            <v-list-item 
                to="/documents"
                :active-class="``"
                >
                <v-list-item-icon >
                    <v-icon v-text="'mdi-gavel'" />
                </v-list-item-icon>
                <v-list-item-content >
                    <v-list-item-title v-text="'Нормативная база'" />
                </v-list-item-content>
            </v-list-item>
            <v-list-item 
                to="/normative"
                :active-class="``"
                >
                <v-list-item-icon >
                    <v-icon v-text="'mdi-weight-lifter'" />
                </v-list-item-icon>
                <v-list-item-content >
                    <v-list-item-title v-text="'Нормативы'" />
                </v-list-item-content>
            </v-list-item>
            <v-list-item 
                to="/plan-cons"
                :active-class="``"
                >
                <v-list-item-icon >
                    <v-icon v-text="'mdi-note-check-outline'" />
                </v-list-item-icon>
                <v-list-item-content >
                    <v-list-item-title v-text="'План-конспекты'" />
                </v-list-item-content>
            </v-list-item>



            <v-list-item v-if="$auth.user().isAdmin"
                to="/admin"
                :active-class="``"
                >
                <v-list-item-icon >
                    <v-icon v-text="'mdi-account-hard-hat'" />
                </v-list-item-icon>
                <v-list-item-content >
                    <v-list-item-title v-text="'Админ. панель'" />
                </v-list-item-content>
            </v-list-item>
            </v-list>
        <v-divider></v-divider>
        <v-list>
              <v-list-item class="pa-0">
                <v-list-item-content >
                    <v-btn block class="ma-0" @click='reference=true'><v-icon color="red" v-text="'mdi-book'" />&nbsp;&nbsp;Справочник РТП</v-btn>
                </v-list-item-content>
            </v-list-item>
                <v-list-item class="pa-0"  v-if="$auth.user().userGroups.find(val => val.group.level=='station' || val.group.level=='garrison' || val.group.level=='control' || val.group.level=='center' || val.group.level=='guard')">
                <v-list-item-content >
                    <v-btn block class="ma-0" @click='serviceArea=true'><v-icon color="primary" v-text="'mdi-map'" />&nbsp;&nbsp;Район выезда</v-btn>
                </v-list-item-content>
            </v-list-item>
        </v-list>

            <!---Sidebar Items -->
        <v-dialog v-model="serviceArea" fullscreen hide-overlay transition="dialog-bottom-transition"  content-class="serviceDialog">
            <v-card lazy>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="serviceArea = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Район выезда</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <service/>
            </v-card>
        </v-dialog>
        <v-dialog v-model="reference" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="reference = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Справочник РТП</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <reference/>
            </v-card>
        </v-dialog>
    </v-navigation-drawer>
</template>
<style>
    .serviceDialog{
        overflow: hidden;
    }
    </style>
<script>
    import reference from '@/views/reference/index'
    import service from '@/views/serviceArea/index'

    import { mapState } from "vuex";
    import SidebarItems from "./SidebarItems";
    export default {
        name: "Sidebar",
        props: {
            expandOnHover: {
                type: Boolean,
                default: false
            }
        },
        components: {
            reference,
            service
        },
        data: () => ({
                serviceArea: false,
                reference: false,
                items: SidebarItems
            }),
        computed: {
            ...mapState(["SidebarColor", "SidebarBg"]),
            Sidebar_drawer: {
                get() {
                    return this.$store.state.Sidebar_drawer;
                },
                set(val) {
                    this.$store.commit("SET_SIDEBAR_DRAWER", val);
                }
            }
        },
        watch: {
            "$vuetify.breakpoint.smAndDown"(val) {
                this.$emit("update:expandOnHover", !val);
            }
        },

        methods: {
            groupLink(){
                if(this.$auth.user().userGroups.findIndex(el=> el.group.level=='center' || el.group.level=='control' || el.group.level=='garrison' || el.group.level=='station' || (el.group.level=='guard' && el.group.admins==true)) !==-1){
                    return true
                }
                return false
            }
        }
    };
</script>
<style lang="scss">
    #main-sidebar{
        box-shadow:1px 0 20px rgba(0,0,0,.08);
        -webkit-box-shadow:1px 0 20px rgba(0,0,0,.08);
        .v-navigation-drawer__border{
            display: none;
        }
        .v-list {
            padding: 8px 15px;
        }
        .v-list-item{
            &__icon--text,
            &__icon:first-child{
                justify-content: center;
                text-align: center;
                width: 20px;

            }
        }
        .profile-bg{
            background:url('../../../assets/images/user-info.jpg') no-repeat;
            .v-avatar{
                padding:45px 0;
            }
        }


    }
</style>